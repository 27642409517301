import { SupportedBanks } from 'components/src/components/Elements/UploadFileToConvert/UploadFileToConvert';
import * as React from 'react';
import { apiRequest } from './apiRequest';
import { API_URL } from './request';

const CONVERT_URL = `${API_URL}/upload`;
console.log('temp ', ' zmienna CONVERT_URL', CONVERT_URL)
type OnUploadSuccessParams = {
  jobId: string;
  fileName: string;
};

type FileUploadHookParams = {
  onUploadSuccess(params: OnUploadSuccessParams);
  onUploadFailure({ error, fileName }: { error: any, fileName: string });
};

export const convertRequestHook = ({ onUploadSuccess, onUploadFailure }: FileUploadHookParams) => {
  const [isSending, setIsSending] = React.useState(false);
  const handleSend = React.useCallback(async (file: File, bankName: SupportedBanks = 'auto', companyId?: string) => {
    const fileName = file.name;
    if (isSending) {
      console.log('temp ',  'w isSending dla convertRequestHook')
      return;
    }

    setIsSending(true);

    const formData = new FormData();
    formData.append('fileField', file);

    if (companyId) {
      console.log('temp ', 'convertRequestHook:', 'if companyId')
      formData.append('companyId', companyId);
    }

    try {
      const url = bankName ? `${CONVERT_URL}/${bankName}` : `${CONVERT_URL}/`;
      console.log('temp ', 'url w convertRequestHook^^^: ', url)

      const response = await apiRequest(url, {
        body: formData,
        method: 'POST',
      });

      console.log('temp ', "Odpowiedź RAW:", response);

      console.log('temp ', "odpowiedz z convertRequestHook @@ ", !response ? " nie ma odpowiedzi " : response)

      if (response) {
        console.log('temp ', ' jest response w convertRequestHook O TEN IF SIE PYTAMY: ', response)
        const { jobId } = await response.json();
        console.log('temp ', ' UploadSuccess convertRequestHook JodID', {jobId})
        onUploadSuccess({ jobId, fileName });
      }
    } catch (error) {
      console.log('temp ', 'UploadFailure convertRequestHook')
      onUploadFailure({ error, fileName });
    }
    console.log('temp ', 'convertRequestHook setIsSending(false)')
    setIsSending(false);
  }, [isSending]);

  return { isSending, handleSend };
};
